<template>
<div
  class="v_test_fu_meta d-flex align-center flex-column"
>
  <div
    class="fun_group d-flex align-center flex-shrink-0 flex-wrap flex-shrink-0"
  >
    <div
      class="button"
      @click.stop="initProject"
    >
      initProject
    </div>
    <div
      class="button"
      @click.stop="loadProject"
    >
      loadProject
    </div>
    <div
      class="button"
      @click.stop="saveProject"
    >
      saveProject
    </div>
    <div
      class="button"
      @click.stop="getScenesNum"
    >
      getScenesNum
    </div>
    <div
      class="button"
      @click.stop="addScene"
    >
      addScene
    </div>
    <div
      class="button"
      @click.stop="activeSceneByUuid"
    >
      activeSceneByUuid
    </div>
    <div
      class="button"
      @click.stop="activeSceneByIndex"
    >
      activeSceneByIndex
    </div>
    <div
      class="button"
      @click.stop="delSceneByUuid"
    >
      delSceneByUuid
    </div>
    <div
      class="button"
      @click.stop="delSceneByIndex"
    >
      delSceneByIndex
    </div>
    <div
      class="button"
      @click.stop="loadBaseboardByUrl"
    >
      loadBaseboardByUrl
    </div>
    <div
      class="button"
      @click.stop="loadModelByUrl"
    >
      loadModelByUrl
    </div>
    <div
      class="button"
      @click.stop="loadImage"
    >
      loadImage
    </div>
    <div
      class="button"
      @click.stop="loadVideo"
    >
      loadVideo
    </div>
    <div
      class="button"
      @click.stop="loadAudio"
    >
      loadAudio
    </div>
    <div
      class="button"
      @click.stop="setPosition"
    >
      setPosition
    </div>
    <div
      class="button"
      @click.stop="setScale"
    >
      setScale
    </div>
    <div
      class="button"
      @click.stop="setRotation"
    >
      setRotation
    </div>
    <div
      class="button"
      @click.stop="findByUuid"
    >
      findByUuid
    </div>
    <div
      class="button"
      @click.stop="findByIndex"
    >
      findByIndex
    </div>
    <div
      class="button"
      @click.stop="hideObjects"
    >
      hideObjects
    </div>
    <div
      class="button"
      @click.stop="showObjects"
    >
      showObjects
    </div>
  </div>
  <div
    class="fu_editor"
    id="editor1"
  ></div>
</div>
</template>

<script>
// import '@/utils/FuMeta/EditorSdk'
// import Editor from '@/utils/FuMeta/EditorSdk0327.js'
// import Editor from 'https://www.ifumeta.com/fumeta-online-editor-sdk/sdk/editor-sdk-20240222.js'
// const Editor = require('https://www.ifumeta.com/fumeta-online-editor-sdk/sdk/editor-sdk-20240222.js')

const editorConfig = {
  'menuTool': [
    // 提供系统默认菜单，可通过value：ture或false来控制菜单显隐
    {
      code: 'undo', // 撤销
      value: true
    },
    {
      code: 'redo', // 重做
      value: true
    },
    {
      code: 'save', // 保存
      value: true
    },
    {
      code: 'overview', // 预览
      value: false,
      enable: false
    },
    /* 自定义菜单 */
    {
      icon: "https://www.ifumeta.com/group2/default/20240311/14/44/6/帮助常态.png?name=%E5%B8%AE%E5%8A%A9%E5%B8%B8%E6%80%81.png&download=1",
      activeIcon: "https://www.ifumeta.com/group2/default/20240311/14/45/6/帮助鼠标.png?name=%E5%B8%AE%E5%8A%A9%E9%BC%A0%E6%A0%87.png&download=1",
      title: '自定义',
      code: 'menuToolTest',
      value: true
    },
  ],
}
export default {
  data() {
    return {
      editor: undefined
    }
  },
  mounted () {
    console.log(window)
    // console.log(Editor)
    const editor = new window.Editor({
      id: 'editor1',
      apiKey: '21c422d54b814392930d71c6ad112bcf',
      config: editorConfig,
    })
    console.log(editor)
    this.editor = editor
    // 监听编辑器准备完毕
    editor.onReady(async function (instance) {
      await editor.project.initProject("工程1")
      await editor.scenes.addScene("场景1")
      const res = await editor.loader.loadModelByUrl("https://ifumeta.com/group2/default/20240111/09/15/6/compress_j01vld97yl.glb", "model")
    })

    editor.onClick(async function (instance, data) {
      const { type, res } = data
      console.log('type：' + type + '，res：' + res)
      switch(type) {
        case 'undo':
          console.log('...todo')
          break;
        case 'redo':
          console.log('...todo')
          break;
        case 'save':
          console.log('...todo')
          break;
        case 'menuToolTest':
          console.log('...todo')
          break;
        default: break;
      }
    })

  },
  methods: {
    async initProject () {
      const res = await this.editor.project.initProject('initProject')
      console.log({
        initProjectRes: res
      })
      return res
    },
    async loadProject () {
      const res = await this.editor.project.loadProject()
      console.log({
        loadProjectRes: res
      })
      return res
    },
    async saveProject () {
      const res = await this.editor.project.saveProject()
      console.log({
        saveProjectRes: res
      })
      return res
    },
    async getScenesNum () {
      const res = await this.editor.project.getScenesNum()
      console.log({
        getScenesNumRes: res
      })
      return res
    },
    /** Scene **/
    async addScene () {
      const res = await this.editor.scenes.addScene('场景名')
      console.log({
        addSceneRes: res
      })
      return res
    },
    async activeSceneByUuid () {
      const res = await this.editor.scenes.activeSceneByUuid(1)
      console.log({
        activeSceneByUuidRes: res
      })
      return res
    },
    async activeSceneByIndex () {
      const res = await this.editor.scenes.activeSceneByIndex()
      console.log({
        getScenesNumRes: res
      })
      return res
    },
    async delSceneByUuid () {
      const res = await this.editor.scenes.delSceneByUuid(1)
      console.log({
        delSceneByUuidRes: res
      })
      return res
    },
    /**
     * LOADER
     */
    async loadBaseboardByUrl () {
      const res = await this.editor.loader.loadBaseboardByUrl()
      console.log({
        loadBaseboardByUrlRes: res
      })
      return res
    },
    async loadModelByUrl () {
      const res = await this.editor.loader.loadModelByUrl()
      console.log({
        loadModelByUrlRes: res
      })
      return res
    },
    async loadImage () {
      const res = await this.editor.loader.loadImage()
      console.log({
        loadImageRes: res
      })
      return res
    },
    async loadVideo () {
      const res = await this.editor.loader.loadVideo()
      console.log({
        loadVideoRes: res
      })
      return res
    },
    async loadAudio () {
      const res = await this.editor.loader.loadAudio()
      console.log({
        loadAudioRes: res
      })
      return res
    },
    /**
     * operation
     */
    async setPosition () {
      const res = await this.editor.operation.setPosition()
      console.log({
        setPositionRes: res
      })
      return res
    },
    async setScale () {
      const res = await this.editor.operation.setScale()
      console.log({
        setScaleRes: res
      })
      return res
    },
    async setRotation () {
      const res = await this.editor.operation.setRotation()
      console.log({
        setRotationRes: res
      })
      return res
    },
    async findByUuid () {
      const res = await this.editor.operation.findByUuid()
      console.log({
        findByUuidRes: res
      })
      return res
    },
    async findByIndex () {
      const res = await this.editor.operation.findByIndex()
      console.log({
        findByIndexRes: res
      })
      return res
    },
    async hideObjects () {
      const res = await this.editor.operation.hideObjects()
      console.log({
        hideObjectsRes: res
      })
      return res
    },
    async showObjects () {
      const res = await this.editor.operation.showObjects()
      console.log({
        showObjectsRes: res
      })
      return res
    },

  }
}

</script>

<style scoped lang="scss">
.v_test_fu_meta {
  height: 100vh;
  width: 100vw;
  .fu_editor {
    height: 100%;
    width: 100%;
  }
  .fun_group {
    .button {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 10px;
      margin: 0 3px;
    }
  }
}
</style>