var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_test_fu_meta d-flex align-center flex-column" },
    [
      _c(
        "div",
        {
          staticClass:
            "fun_group d-flex align-center flex-shrink-0 flex-wrap flex-shrink-0",
        },
        [
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.initProject.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      initProject\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadProject.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadProject\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.saveProject.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      saveProject\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.getScenesNum.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      getScenesNum\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addScene.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      addScene\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.activeSceneByUuid.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      activeSceneByUuid\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.activeSceneByIndex.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      activeSceneByIndex\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.delSceneByUuid.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      delSceneByUuid\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.delSceneByIndex.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      delSceneByIndex\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadBaseboardByUrl.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadBaseboardByUrl\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadModelByUrl.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadModelByUrl\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadImage.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadImage\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadVideo.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadVideo\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadAudio.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      loadAudio\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.setPosition.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      setPosition\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.setScale.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      setScale\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.setRotation.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      setRotation\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.findByUuid.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      findByUuid\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.findByIndex.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      findByIndex\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.hideObjects.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      hideObjects\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showObjects.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      showObjects\n    ")]
          ),
        ]
      ),
      _c("div", { staticClass: "fu_editor", attrs: { id: "editor1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }